import React, { Component } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Splash from "../pages/splash/Splash";
import Education from "../pages/education/EducationComponent";
import Experience from "../pages/experience/Experience";
import Opensource from "../pages/opensource/Opensource";
import Contact from "../pages/contact/ContactComponent";
import Academic from "../pages/Academic/AcademicComponent";
import Labs from "../pages/Labs/Labs";
import Classes from "../pages/Classes/Classes";
import Certification from "../pages/Certifications/Certifications";
import Projects from "../pages/projects/Projects";
import Resume from "../pages/Resume/Resume";
import Learningphilosophy from "../pages/Learningphilosophy/Learningphilosophy";
import Reflection from "../pages/Reflection/main";
import Hobbies from "../pages/Hobbies/main";
import { settings } from "../portfolio.js";
import Error404 from "../pages/errors/error404/Error";
import Skills from "../pages/Skills/Skills";
import Volunteer from "../pages/volunteer/main";
// eslint-disable-next-line
import clickEffect from "../components/click";


export default class Main extends Component {
  render() {
    if (settings.isSplash) {
      return (
        <div>
          <HashRouter basename="/">
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => (
                  <Splash {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/home"
                render={(props) => <Home {...props} theme={this.props.theme} />}
              />
              <Route
                path="/experience"
                exact
                render={(props) => (
                  <Experience {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/education"
                render={(props) => (
                  <Education {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/opensource"
                render={(props) => (
                  <Opensource {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/contact"
                render={(props) => (
                  <Contact {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/splash"
                render={(props) => (
                  <Splash {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/projects"
                render={(props) => (
                  <Projects {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/labs"
                render={(props) => (
                  <Projects {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="*"
                render={(props) => (
                  <Error404 {...props} theme={this.props.theme} />
                )}
              />
            </Switch>
          </HashRouter>
        </div>
      );
    } else {
      return (
        <div>
          <clickEffect></clickEffect>
          <HashRouter basename="/">
            <Switch>
              <Route
                path="/hobbies"
                exact
                render={(props) => <Hobbies {...props} theme={this.props.theme} />}
              />
              <Route
                path="/"
                exact
                render={(props) => <Home {...props} theme={this.props.theme} />}
              />
              <Route
                path="/home"
                render={(props) => <Home {...props} theme={this.props.theme} />}
              />
              <Route
                path="/experience"
                exact
                render={(props) => (
                  <Experience {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/education"
                render={(props) => (
                  <Education {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/opensource"
                render={(props) => (
                  <Opensource {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/contact"
                render={(props) => (
                  <Contact {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/academicplan"
                render={(props) => (
                  <Academic {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/labs"
                render={(props) => (
                  <Labs {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/classes"
                render={(props) => (
                  <Classes {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/certification"
                render={(props) => (
                  <Certification {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/skills"
                render={(props) => (
                  <Skills {...props} theme={this.props.theme} />
                )}
              />
              {/* <Route
							path="/splash"
							render={(props) => (
								<Splash
									{...props}
									theme={this.props.theme}
								/>
							)}
						/> */}
              <Route
                path="/projects"
                render={(props) => (
                  <Projects {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/resume"
                render={(props) => (
                  <Resume {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/learningphilosophy"
                render={(props) => (
                  <Learningphilosophy {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/reflection"
                render={(props) => (
                  <Reflection {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/volunteer"
                render={(props) => (
                  <Volunteer {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="*"
                render={(props) => (
                  <Error404 {...props} theme={this.props.theme} />
                )}
              />
            </Switch>
          </HashRouter>
        </div>
      );
    }
  }
}
